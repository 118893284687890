.mentionsLegales{
    .MentionsLegalesContent{
        .mentions{
            display: flex;
            flex-direction: column;
            text-align: center;
        }

        h1{
            color : #ff4d4d;
            text-align: center;
        }
    }

    @media (min-width:1600px){
        .footer{
            position: fixed;
            bottom: 0;
        }
    }
}