.footer{
    background-color: $black;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    bottom: 0;
    color: $white;
}

.socialNetworks{
    display:flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0.4rem ;
}

.socialNetwork{
    padding:0 0.6rem;
}

@media screen and (max-width:767px){
    .footer{
        background-color: $black;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        bottom: 0;
        color: $white;
        position: inherit;
    }
}