$black: #333;
$white: #ddd;

$borderRadius: 25px;
$shadow: 0 1px 3px #333;
$minHeigth: 95vh;

body{
    margin: 0; 
    color: $black;
    height: 100%;
    font-family: Arial, Helvetica, sans-serif; 
}

button{
    cursor: pointer;
}

li{
    list-style-type: none;
}
a{
    text-decoration: none;
    color: $white;
    cursor: pointer;
}
a:visited{
    color: $white;
}

