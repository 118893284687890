.quiSuisJe{

    .title1, .presentation, .conclusion{
        text-align: center;
    }

    .description{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-right: 3%;
    }

    .paragraphe{
        padding: 1%;
        margin: 1%;
        background-color: $black;
        color: $white;
        border-radius: 30px;
    }

    h1, p{
        text-align: left;
        line-height: 20px;
    }

    .chaine{
        color: rgb(114, 114, 114);
    }

    @media (min-width:1600px){
        .foot{
            position:absolute;
            bottom:0;
            width:100%;
        }
    }

    @media (max-width:750px){
        .description{
            display: flex;
            flex-direction: column;
        }

        .paragraphe, .presentation{
            padding : 5%;
        }

        .paragraphe{
            margin-bottom: 4%;
            margin-right: 5%;
        }

        .conclusion{
            margin: 5%;
        }

    }

}