.menubar{
    background-color: $black;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: auto;
    min-width: 75px;
    padding: 1rem;
}

.is-active{
    background: $white;
}
.navbar_links{
    display: flex;
}

.navbar_link{
   padding: 0 4.3rem;
   font-size: 140%;
   cursor: pointer;
}

.nav-active{
    padding: 0 4.3rem;
    font-size: 140%;
    cursor: pointer;
    border-right: solid;
    border-left: solid;
}

.navbar_burger{
    width: 40px;
    height: 40px;
    background: transparent;
    border:none;
    color:inherit;
    cursor: none;
}

img{
    width: 100%;
}

.home_link{
    width: 75px;
}

@media (max-width:900px){
    img{
        width: 100%;
    }
    
    .home_link{
        width: 55px;
    }
}

@media (max-width:767px){
    .navbar_links{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        
        right: -100vw;
        bottom: 0;
        height: 100vh;
        width: 0;
        visibility: hidden;
        background-color: $black;
        transition: all .8s ease-out;
    }

    .nav-active{
        border-right:none;
        border-left:none;
    }

    .navbar_link{
        display: block;
        padding: 1.5rem;
        font-size: 5vw;
    }

    .show-nav .navbar_links{
        right: 0;
        width: 100vw;
        visibility: visible;
    }

    

    .navbar_link{
        display: block;
        padding: 2.5rem;
        font-size: 5vw;
    }

    .navbar_burger{
        display: block;
        position: fixed;
        right : 1rem;
    }

    .navbar_burger:hover{
        cursor: pointer;
    }

    .burger_bar, .burger_bar::before, .burger_bar::after{
        display: block;
        width: 40px;
        height: 3px;
        position: relative;
        border-radius: 3px;
        background: $white;
        transition: all .5s ease-in-out;
    }

    .burger_bar::before, .burger_bar::after{
        content: "";
        position: absolute;
        left: 0;
    }

    .burger_bar::before{
        transform: translateY(-12px);
    }

    .burger_bar::after{
        transform: translateY(12px);
    }

    .show-nav .burger_bar{
        width:0;
        background: transparent;
    }

    .show-nav .burger_bar::before{
        transform: rotate(45deg);
    }

    .show-nav .burger_bar::after{
        transform: rotate(-45deg);
    }

    /*burger button animation*/
    .navbar_item{
        transform: translateY(100vh);
    }

    .show-nav .navbar_item{
        transform: translateY(0);
    }

    .show-nav .slideInDown1{
        transition: all 1s ease-out;
    }

    .show-nav .slideInDown2{
        transition: all 1.1s ease-out;
    }

    .show-nav .slideInDown3{
        transition: all 1.2s ease-out;
    }
}