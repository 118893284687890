.contactContent{

    h1{
      text-align: center;
    }

    .paragraphe{
      padding: 1% 0 2% 0;
      margin-left: 10%;
      margin-right: 10%;
      background-color: $black;
      border-radius: 30px;
      color: $white;
      text-align: center;
    }

    .contact-form {
      margin: 2rem auto 2rem;
      max-width: 350px;
      border: 1px solid $white;
      border-radius: 30px;
      background: $black;
      padding: 0 30px 10px;
      box-shadow: 0px 4px 10px rgba(51, 51, 51, 0.637);
    }

    h2 {
      margin-top: 35px;
      text-align: center;
      color : $white;
    }

    .form-content {
      display: grid;
    }
    input, textarea {
      background: none;
      font-size: 1.1rem;
      border-bottom: 1px solid $white;
      color: $white;
    }
    input::placeholder, 
    textarea::placeholder {
    color: #fff;
    }
    input,
    .email-content,
    textarea {
      width: 90%;
      margin: 0 auto;
    }
    input {
      padding: 24px 0 12px;
      border : none;
      outline: none;
    }
    .email-content {
      position: relative;
    }
    .email-content input {
      width: 100%;
    }
    #not-mail {
      display: none;
      position: absolute;
      top: 4px;
      color: #ff4d4d;
      right: 0;
      transform-origin: 50% 50%;
    }

    @keyframes dongle {
      0% {
        transform: translate(0px, 0px);
      }
      10% {
        transform: translate(-10px, 0px);
      }
      20% {
        transform: translate(10px, 0px);
      }
      30% {
        transform: translate(-10px, 0px);
      }
      40% {
        transform: translate(10px, 0px);
      }
      50% {
        transform: translate(-10px, 0px);
      }
      60% {
        transform: translate(10px, 0px);
      }
      70% {
        transform: translate(-10px, 0px);
      }
      80% {
        transform: translate(10px, 0px);
      }
      90% {
        transform: translate(-10px, 0px);
      }
      100% {
        transform: translate(0px, 0px);
      }
    }
    textarea {
      resize: none;
      padding: 24px 0;
      font-family: Arial, Helvetica, sans-serif;
    }
    .button {
      font-size: 1.3rem;
      margin-top: 15px;
      color: $white;
      border: none;
      cursor: pointer;
      transition: .2s;
      width: 100%;
    }
    .button:hover {
      letter-spacing: 2px;
    }
    .error::placeholder {
      color: #ff4d4d;
    }

    .form-message {
      margin-top: 10px;
      padding: 12px;
      opacity: 0;
      transition: 0.2s ease;
      color: black;
      border-radius: 4px;
      box-shadow: 0 0 2px rgba(51, 51, 51, 0.3);
    }

    .verif_rgpd_checkbox{
      border: 1px solid $white;
      margin: 4% 0 0 4%;
      width: 6%;  
    }

    .mentionsLink{
      color : #777575;
    }

    span{
        color :$white
    }

    @media (max-width:750px){
      .paragraphe{
        padding-right: 5%;
        padding-left: 5%;
        padding-bottom: 5%;
      }
    }
}

