.portfolio{
    
    h1, p{
        text-align: center;
    }

    .foot{
        position:absolute;
        bottom:0;
        width:100%;
    }

}
